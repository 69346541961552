import React, { useEffect, useMemo, useState } from 'react';
import * as Message from '../../components/shared/Message';
import * as _movimentacoesService from '../../service/movimentacoes-service';
import * as Yup from 'yup';
import * as _matriculaService from '../../service/matricula-service';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import csatList from "../../utils/csatList";
import tipoBolsaEnum from "../../utils/tipoBolsaEnum";
import { Unidade } from 'src/components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect';
import { Matricula } from 'src/components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect';
import { Permission } from 'src/recoil/atoms/permissionsState';
import InfoComponent from 'src/components/InfoComponent';
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Button, CircularProgress, Container, Grid, Grow, MenuItem, Stack, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Cancel, Pending, Person, CheckCircle } from '@mui/icons-material';
import { SolicitacaoBolsaResponse } from 'seb-graph-api-types/generated';
import { CustomMuiTable } from 'src/components/CurstomMuiTable';

interface Props {
    Matricula: Matricula;
    Unidade: Unidade;
    permissions: Permission[];
    handleShowCsat: (key: string) => void;
}

interface DadosAtuais {
    matriculaId: string;
    tipoDaBolsa: string;
    percentualBolsa: number;
    hasMovimentacaoPendente: boolean;
    movimentacaoBolsaId: string;
    movimentacaoMotivoAlteracao: string;
    movimentacaoTipoDaBolsa: string;
    movimentacaoPercentualBolsa: number;
}

interface Selects {
    motivoAlteracao: string;
    tipoBolsaNova: string;
    percentualBolsaNova: string;
    dataInicio: string;
    dataTermino: string;
    descricao: string;
    nomeSolicitante: string;
}

const initialSelectsValues: Selects = {
    motivoAlteracao: "",
    tipoBolsaNova: "",
    percentualBolsaNova: "",
    dataInicio: "",
    dataTermino: "",
    descricao: "",
    nomeSolicitante: ""
}

const initialDadosAtuais: DadosAtuais = {
    matriculaId: "",
    tipoDaBolsa: "",
    percentualBolsa: 0,
    hasMovimentacaoPendente: false,
    movimentacaoBolsaId: "",
    movimentacaoMotivoAlteracao: "",
    movimentacaoTipoDaBolsa: "",
    movimentacaoPercentualBolsa: 0
}

const Bolsas = (props: Props) => {
    const { Matricula, Unidade, permissions, handleShowCsat } = props;
    const [historicoBolsas, setHistoricoBolsas] = useState<SolicitacaoBolsaResponse[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(3);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [listaTipoBolsaNova, setListaTipoBolsaNova] = useState<any>([]);
    const [bolsaEmSolicitacao, setBolsaEmSolicitacao] = useState<SolicitacaoBolsaResponse>(null);
    const [dadosAtuais, setDadosAtuais] = useState<DadosAtuais>(initialDadosAtuais);
    const [selects, setSelects] = useState<Selects>(initialSelectsValues);
    const [permiteMovimentarAvista, setPermiteMovimentarAvista] = useState<boolean>(true);

    const getBolsaAtual = async () => {
        setIsLoading(true);
        try {
            setSelects(initialSelectsValues);
            const fetchBolsaAtual = async () => {
                const dadosAtuais = await _movimentacoesService.GetBolsaAtual(Matricula.idMatricula);
                if (dadosAtuais && dadosAtuais.hasMovimentacaoPendente) {
                    const tipoBolsaNova = listaTipoBolsaNova.find(tipo => tipo.nome === dadosAtuais.movimentacaoTipoDaBolsa.replace(" ", ""));
                    handleSelectBolsa(tipoBolsaNova);
                    setSelects({
                        ...selects,
                        motivoAlteracao: dadosAtuais.movimentacaoMotivoAlteracao,
                        tipoBolsaNova: tipoBolsaNova?.value ?? "",
                        percentualBolsaNova: `${dadosAtuais.movimentacaoPercentualBolsa}%`,
                        dataInicio: dadosAtuais.dataInicio.substring(0, 10),
                        dataTermino: dadosAtuais.dataTermino.substring(0, 10),
                        descricao: dadosAtuais?.descricao ?? "",
                        nomeSolicitante: dadosAtuais?.nomeSolicitante ?? ""
                    });
                }
                setDadosAtuais(dadosAtuais ?? []);
                const bolsasMatricula = await _matriculaService.GetBolsasByMatricula(Matricula.idMatricula);
                if (bolsasMatricula && bolsasMatricula.length > 0) {
                    const bolsaEmSolicitacao = bolsasMatricula.find(bolsa => bolsa.razaoStatus.toLowerCase() !== "reprovada" && bolsa.razaoStatus.toLowerCase() !== "aprovada" && bolsa.razaoStatus.toLowerCase() !== "cancelada" && bolsa.razaoStatus.toLowerCase() !== "concluida" && bolsa.razaoStatus.toLowerCase() !== "concluída" );
                    if (bolsaEmSolicitacao)
                        setBolsaEmSolicitacao(bolsaEmSolicitacao);
                    const ordenatedBolsas = bolsasMatricula.sort((a, b) => new Date(b.dataCriacao).getTime() - new Date(a.dataCriacao).getTime());
                    setHistoricoBolsas(ordenatedBolsas);
                }
            };
            await fetchBolsaAtual();
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setHistoricoBolsas([]);
        setListaTipoBolsaNova([]);
        setBolsaEmSolicitacao(null);
        setSelects(initialSelectsValues);
        setDadosAtuais(initialDadosAtuais);
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const listaTipoBolsa = await _movimentacoesService.GetListaTipoBolsa();
                if (listaTipoBolsa && listaTipoBolsa.length > 0) {
                    const hasBolsaIrmaoAccess = (permissions || []).find(p => p.permissionName === "movimentacoes.bolsa.irmao");
                    const canUseBolsaIrmao = bolsa => (bolsa.motivoBolsa.valor === tipoBolsaEnum.Irmao && hasBolsaIrmaoAccess) || bolsa.motivoBolsa.valor !== tipoBolsaEnum.Irmao;
                    const novaListaBolsa = listaTipoBolsa.filter(canUseBolsaIrmao);
                    setListaTipoBolsaNova(novaListaBolsa);
                }
                const permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(Matricula.idMatricula, Unidade.id, 284360006, 0);
                setPermiteMovimentarAvista(permiteMovimentarAvista.value);

            } catch (e) {
                console.error(e);
            } finally {
                await getBolsaAtual();
                setIsLoading(false);
            }
        };
        fetchData();
    }, [Matricula, Unidade, permissions]);

    const handleSelectBolsa = (bolsa) => {
        setSelects({
            ...selects,
            tipoBolsaNova: bolsa?.value ?? "",
            percentualBolsaNova: "0%"
        });
        formik.setFieldValue("tipoBolsaNova", bolsa?.value ?? "");
    }



    const validationSchema = Yup.object({
        motivoAlteracao: Yup.string().required('Motivo da alteração é obrigatório'),
        tipoBolsaNova: Yup.string().required('Tipo de bolsa é obrigatório'),
        percentualBolsaNova: Yup.string().required('Percentual da bolsa é obrigatório'),
        dataInicio: Yup.string().required('Data de início é obrigatória'),
        dataTermino: Yup.string().required('Data de término é obrigatória')
            .test('dataTermino', 'Data de término deve ser maior que a data de início', (value) => {
                if (!formik.values.dataInicio) return true;
                if (value && formik.values.dataInicio) {
                    return new Date(value) > new Date(formik.values.dataInicio);
                }
                return true;
            }),
        descricao: Yup.string(),
    });

    const formik = useFormik({
        initialValues: selects,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const percentualBolsaNova = Number(values.percentualBolsaNova);
                const tipoBolsaNova = Number(values.tipoBolsaNova);
                const request = {
                    matriculaId: Matricula.idMatricula,
                    motivoAlteracao: values.motivoAlteracao,
                    tipoBolsaNova: tipoBolsaNova,
                    percentualBolsaNova: parseFloat(percentualBolsaNova.toLocaleString("en-US", {
                        maximumFractionDigits: 2
                    })),
                    dataInicio: values.dataInicio,
                    dataTermino: values.dataTermino,
                    descricao: values.descricao,
                    nomeSolicitante: values.nomeSolicitante
                };
                await _movimentacoesService.SolicitaAltercaoBolsaAtual(request);
            } catch (e) {
                Message.ExibeMensagem("Erro ao alterar a bolsa!", "2", true);
                console.error(e);
            } finally {
                Message.ExibeMensagem("Solicitação registrada com sucesso!!", '0', true);
                await getBolsaAtual();
                setIsLoading(false);
                if (handleShowCsat) handleShowCsat(csatList.bolsas);
            }
        }
    });

    const columns: GridColDef[] = [
        {
            field: 'tipoBolsaNome',
            headerName: 'Tipo de Bolsa',
            width: 150
        },
        {
            field: 'nomeSolicitante',
            headerName: 'Nome do Solicitante',
            width: 200
        },
        {
            field: 'percentual',
            headerName: '% da Bolsa',
            width: 130,
            valueGetter: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                `${params.row.percentual}%`
        },
        {
            field: 'vigenciaDe',
            headerName: 'Vigência de',
            width: 130,
            valueGetter: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                new Date(params.row.vigenciaDe).toLocaleDateString()
        },
        {
            field: 'vigenciaAte',
            headerName: 'Vigência até',
            width: 130,
            valueGetter: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                new Date(params.row.vigenciaAte).toLocaleDateString()
        },
        {
            field: 'razaoStatus',
            headerName: 'Situação',
            width: 130,
            renderCell: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: (theme : Theme) => {
                            if (params.row.razaoStatus.toLowerCase().includes("cancelada") ||
                                params.row.razaoStatus.toLowerCase().includes("reprovada")) {
                                return theme.palette.error.main;
                            } else if (params.row.razaoStatus.toLowerCase().includes("aprovada") ||
                                params.row.razaoStatus.toLowerCase().includes("concluida")) {
                                return theme.palette.success.main;
                            } else {
                                return theme.palette.info.main;
                            }
                        }
                    }}
                >
                    {params.row.razaoStatus.toLowerCase().includes("cancelada") ||
                        params.row.razaoStatus.toLowerCase().includes("reprovada") ?
                        <Cancel
                            color="error"
                        />
                        : params.row.razaoStatus.toLowerCase().includes("aprovada") ||
                        params.row.razaoStatus.toLowerCase().includes("concluida") ?
                            <CheckCircle
                                color="success"
                            />
                            :
                            <Pending
                                color="info"
                            />
                    }
                    {params.row.razaoStatus}
                </Box>
        },
        {
            field: 'dataCriacao',
            headerName: 'Data de Criação',
            width: 130,
            valueGetter: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                new Date(params.row.dataCriacao).toLocaleDateString()
        },
        {
            field: 'descricao',
            headerName: 'Descrição',
            width: 200,
            renderCell: (params: GridValueGetterParams<SolicitacaoBolsaResponse>) =>
                <Tooltip title={params.row.descricao}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {params.row.descricao}
                    </Box>
                </Tooltip>
        },
    ];


    const paginatedRows = useMemo(() => {
        return historicoBolsas.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row, index) => ({
            ...row,
            id: index.toString()
        }));
    }, [historicoBolsas, page, rowsPerPage]);

    if (isLoading) {
        return <LoadingAnimation />
    }
    if (!permiteMovimentarAvista) {
        return (
            <div>
                <div className=' form-group d-flex justify-content-center'>
                    <h3 style={{ color: '#0068b2' }}>
                        <strong>AVISO</strong>
                    </h3>
                </div>
                <div className=' form-group d-flex justify-content-center'>
                    <h5 style={{ color: '#0068b2' }}>
                        {' '}
                        O Template selecionado não permite movimentações onde o pagamento
                        é Avista.
                    </h5>
                </div>
            </div>
        )
    }
    if (bolsaEmSolicitacao) {
        return (
            <React.Fragment>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Grid
                        container
                        justifyContent="center"
                    >
                        <Grid
                            md={12}
                            sx={{
                                mb: 3
                            }}
                        >
                            <InfoComponent
                                title='Solicitação de Bolsa em análise'
                                desc={`A solicitação de bolsa da matrícula ${Matricula?.codigoMatricula} foi realizada com sucesso e aguarda aprovação.`}
                                type={'info'}
                            />
                        </Grid>
                        <Container>
                            <Grid
                                md={12}
                            >
                                <Grid
                                    container
                                    alignItems='center'
                                    sx={{
                                        backgroundColor: 'var(--bs-gray-200)',
                                        borderRadius: 1,
                                        px: 4,
                                        py: 8
                                    }}
                                >
                                    <Grow
                                        in={!isLoading}
                                        easing="ease-out"
                                        mountOnEnter
                                        unmountOnExit
                                        timeout={800}
                                    >
                                        <Grid
                                            container
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    sx={{
                                                        mb: 3
                                                    }}
                                                >
                                                    Informações da Bolsa Solicitada
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <Stack
                                                    direction={{ md: 'row', sm: 'column' }}
                                                    spacing={3}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Tipo da Bolsa"
                                                        name="tipoBolsaNova"
                                                        size="small"
                                                        value={bolsaEmSolicitacao.tipoBolsaNome}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="% da Bolsa"
                                                        name="percentualBolsaNova"
                                                        size="small"
                                                        value={`${bolsaEmSolicitacao.percentual}%`}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Vigência de"
                                                        name="dataInicio"
                                                        size="small"
                                                        value={new Date(bolsaEmSolicitacao.vigenciaDe).toLocaleDateString()}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Vigência até"
                                                        name="dataTermino"
                                                        size="small"
                                                        value={new Date(bolsaEmSolicitacao.vigenciaAte).toLocaleDateString()}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <Stack
                                                    direction={{ md: 'row', sm: 'column' }}
                                                    spacing={3}
                                                    mt={2}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Situação"
                                                        name="situacao"
                                                        size="small"
                                                        value={bolsaEmSolicitacao.razaoStatus}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Nome do Solicitante"
                                                        name="nomeSolicitante"
                                                        size="small"
                                                        value={bolsaEmSolicitacao.nomeSolicitante}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Data de Criação"
                                                        name="dataCriacao"
                                                        size="small"
                                                        value={new Date(bolsaEmSolicitacao.dataCriacao).toLocaleDateString()}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                <Stack
                                                    direction={{ md: 'row', sm: 'column' }}
                                                    spacing={3}
                                                    mt={2}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Descrição"
                                                        name="descricao"
                                                        size="small"
                                                        value={bolsaEmSolicitacao.descricao}
                                                        variant="filled"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        multiline
                                                        minRows={3}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grow>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </Box >
            </React.Fragment >
        )
    }
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
            }}
        >
            <Grid
                container
                justifyContent="center"
                alignItems='center'
                spacing={3}
                sx={{
                    py: 4,
                    mb: 3
                }}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Stack
                        spacing={1}
                        direction={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        sx={{
                            mb: 4
                        }}
                    >
                        <Typography variant="h4">
                            Informações da Matrícula
                        </Typography>
                        <Person
                            sx={{
                                fontSize: '2rem',
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                >
                    <Stack
                        direction={{ md: 'row', sm: 'column' }}
                    >
                        <TextField
                            fullWidth
                            label="Nome"
                            name="estudante_Name"
                            size="small"
                            value={Matricula?.estudante_Name}
                            variant="filled"
                            sx={{
                                '& .MuiInputBase-input': {
                                    textOverflow: 'ellipsis'
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                >
                    <Stack
                        direction={{ md: 'row', sm: 'column' }}
                    >
                        <TextField
                            fullWidth
                            label="Matrícula"
                            name="codigoMatricula"
                            size="small"
                            value={Matricula?.codigoMatricula}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                >
                    <Stack
                        direction={{ md: 'row', sm: 'column' }}
                    >
                        <TextField
                            fullWidth
                            label="Tipo da Bolsa"
                            name=""
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            value={dadosAtuais?.tipoDaBolsa}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                >
                    <Stack
                        direction={{ md: 'row', sm: 'column' }}
                    >
                        <TextField
                            fullWidth
                            label="% da Bolsa"
                            name=""
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            value={`${dadosAtuais?.tipoDaBolsa.toLowerCase() === "sem bolsa" ? "0" : dadosAtuais?.percentualBolsa}%`}
                            variant="filled"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Stack>
                </Grid>
                {historicoBolsas.length > 0 &&
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                        >
                            <Typography variant="h5">
                                Histórico de Bolsas
                            </Typography>
                        </Stack>
                    </Grid>
                }
                {historicoBolsas.length > 0 &&
                    <Grid
                        item
                        xs={12}
                        pt={0}
                    >
                        <CustomMuiTable
                            columns={columns}
                            count={historicoBolsas?.length as number | null | undefined}
                            loading={isLoading}
                            items={paginatedRows}
                            checkboxSelection={false}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            rowsPerPageOptions={[rowsPerPage]}
                            onRowsPerPageChange={() => setRowsPerPage(rowsPerPage)}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    </Grid>
                }
            </Grid>
            <form
                onSubmit={formik.handleSubmit}
            >
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems='center'
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            spacing={1}
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{
                                mb: 4
                            }}
                        >
                            <Typography variant="h4">
                                Nova Alteração de Bolsa
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            direction={{ md: 'row', sm: 'column' }}
                            spacing={3}
                            mb={3}
                        >
                            <TextField
                                fullWidth
                                label="Motivo da Alteração"
                                name="motivoAlteracao"
                                size="small"
                                value={formik.values.motivoAlteracao}
                                variant="filled"
                                onChange={formik.handleChange}
                                error={!!(formik.touched.motivoAlteracao && formik.errors.motivoAlteracao)}
                                helperText={formik.touched.motivoAlteracao && formik.errors.motivoAlteracao}
                            />
                            <TextField
                                fullWidth
                                label="Tipo de Bolsa"
                                name="tipoBolsaNova"
                                size="small"
                                select
                                value={formik.values.tipoBolsaNova}
                                variant="filled"
                                onChange={formik.handleChange}
                                error={!!(formik.touched.tipoBolsaNova && formik.errors.tipoBolsaNova)}
                                helperText={formik.touched.tipoBolsaNova && formik.errors.tipoBolsaNova}
                            >
                                {listaTipoBolsaNova
                                    .sort((a, b) => a.nome.localeCompare(b.nome))
                                    .map((bolsa, index) => (
                                        <MenuItem
                                            key={index}
                                            value={bolsa.motivoBolsa.valor}
                                            onClick={() => handleSelectBolsa(bolsa)}
                                        >
                                            {bolsa.nome}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            <TextField
                                fullWidth
                                label="% da Bolsa"
                                name="percentualBolsaNova"
                                size="small"
                                value={formik.values.percentualBolsaNova}
                                variant="filled"
                                type="number"
                                inputProps={{
                                    maxLength: 5
                                }}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    if (value === '' ||
                                        (parseFloat(value) >= 0 && parseFloat(value) <= 100 && !value.includes('e') &&
                                            (value.includes('.') ? value.split('.')[1].length <= 2 : true) &&
                                            (value.includes(',') ? value.split(',')[1].length <= 2 : true)
                                        )) {
                                        formik.setFieldValue('percentualBolsaNova', value);
                                    }
                                }}
                                error={!!(formik.touched.percentualBolsaNova && formik.errors.percentualBolsaNova)}
                                helperText={formik.touched.percentualBolsaNova && formik.errors.percentualBolsaNova}
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            direction={{ md: 'row', sm: 'column' }}
                            spacing={3}
                            mb={3}
                        >
                            <TextField
                                fullWidth
                                label="Bolsa Inicia em"
                                name="dataInicio"
                                size="small"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.dataInicio}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!(formik.touched.dataInicio && formik.errors.dataInicio)}
                                helperText={formik.touched.dataInicio && formik.errors.dataInicio}
                            />
                            <TextField
                                fullWidth
                                label="Bolsa Termina em"
                                name="dataTermino"
                                size="small"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.dataTermino}
                                onChange={formik.handleChange}
                                error={!!(formik.touched.dataTermino && formik.errors.dataTermino)}
                                helperText={formik.touched.dataTermino && formik.errors.dataTermino}
                            />
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        mb={3}
                    >
                        <TextField
                            fullWidth
                            label="Descrição da Solicitação"
                            name="descricao"
                            size="small"
                            value={formik.values.descricao}
                            variant="filled"
                            onChange={formik.handleChange}
                            multiline
                            minRows={3}
                            error={!!(formik.touched.descricao && formik.errors.descricao)}
                            helperText={formik.touched.descricao && formik.errors.descricao}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <Button
                            name="Confirmar"
                            className="btn btn-primary"
                            fullWidth
                            variant='contained'
                            onClick={() => formik.handleSubmit()}
                            startIcon={isLoading && <CircularProgress color="primary" size={20} />}
                        >
                            {isLoading ? 'Aguarde...' : 'Confirmar'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default withUnidadeMatricula(Bolsas, "Bolsas", csatList.bolsas);
