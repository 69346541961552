import React, { useState } from 'react';
import HorarioSemanal from './horario-semanal';
import { Container, Grid, Stack, Tooltip, Typography, Divider, Grow, Skeleton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Intervals } from "../../types/guardian-types";

interface Props {
  entryTime: Intervals[];
  exitTime: Intervals[];
  loadingHorarios: boolean;
  handleSave: (intervals: Intervals[], isEntry: boolean) => void;
}

const ModalHorario = (props: Props) => {
  const {
    loadingHorarios,
    entryTime,
    exitTime,
    handleSave,
  } = props;
  const [selectedDay, setSelectedDay] = useState<string>('')
  const [openModalEntry, setOpenModalEntry] = useState(false);
  const [openModalExit, setOpenModalExit] = useState(false);

  const setModalSettings = (isEntry: boolean, selectedDay: string) => {
    isEntry ? setOpenModalEntry(true) : setOpenModalExit(true);
    setSelectedDay(selectedDay);
  }

  if (loadingHorarios) return (
    <Container
      sx={{ 
        mt: 2,
        pr: '0 !important',
        pl: '0 !important'
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        marginBottom={3}
      >
        <Skeleton variant="rectangular" width="100%" height={400} />
        <Skeleton variant="rectangular" width="100%" height={400} />
      </Stack>
    </Container>
  );
  return (
    <Container
      sx={{ 
        mt: 2,
        pr: '0 !important',
        pl: '0 !important'
      }}
    >
      <Grow
        in={!loadingHorarios}
        easing="ease-out"
        mountOnEnter
        unmountOnExit
        timeout={800}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6" align="left">
                  Horários de Entrada
                </Typography>
                <Tooltip title="Tabela referente aos horários de entrada permitidos nas catracas.">
                  <InfoIcon 
                    color='info'
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 3,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '100px',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <HorarioSemanal
                  openModal={openModalEntry}
                  selectedDay={selectedDay}
                  isEntrada={true}
                  intervals={entryTime}
                  setModalSettings={setModalSettings}
                  setOpenModal={setOpenModalEntry}
                  handleSave={handleSave}
                />
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="h6" align="left">
                  Horários de Saída
                </Typography>
                <Tooltip title="Tabela referente aos horários de saída permitidos nas catracas.">
                  <InfoIcon 
                    color='info'
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 3
              }}
            >
              <Grow
                in={!loadingHorarios}
                easing="ease-out"
                mountOnEnter
                unmountOnExit
                timeout={800}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <HorarioSemanal
                    openModal={openModalExit}
                    isEntrada={false}
                    selectedDay={selectedDay}
                    intervals={exitTime}
                    setModalSettings={setModalSettings}
                    setOpenModal={setOpenModalExit}
                    handleSave={handleSave}
                  />
                </Grid>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Container>
  );
}

export default ModalHorario;