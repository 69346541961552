import { gql } from '@apollo/client'
import { dispatchGraphError } from '../utils/dispatchGraphError'
import { GuardianRuleInput, GuardianRuleUpdateInput } from 'seb-graph-api-types/generated'

interface Intervals {
  id: number
  startTime: string
  endTime: string
  isSunday: boolean
  isMonday: boolean
  isTuesday: boolean
  isWednesday: boolean
  isThursday: boolean
  isFriday: boolean
  isSaturday: boolean
}

interface RuleControllerGroup {
  id: number
  ruleId: number
  controllerGroupId: number
}

export interface RuleType {
  id: number;
  uId: string;
  name: string;
  unitCrmId: string;
  serviceCrmId: string;
  intervals: Intervals[];
  ruleControllerGroup: RuleControllerGroup[];
  ruleVisits: RuleVisits[];
}

export interface RuleVisits {
  id: number;
  ruleId: number;
  visitId: number;
}

export interface RuleQueryResponse {
  succeeded: boolean;
  errors: any;
  data: RuleType;
}

export async function GetRulesByUnit (
  idUnidade: string
) {
  // @ts-ignore
  const client = window.apolloClient
  const {
    data: { getRulesByUnit },
    errors
  } = await client.query({
    query: gql`
      query GetRulesByUnit($idUnidade: ID!) {
        getRulesByUnit(idUnidade: $idUnidade) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            serviceCrmId
          }
        }
      }
    `,
    variables: {
      idUnidade
    }
  })

  // @ts-ignore
  dispatchGraphError(errors)

  return getRulesByUnit
}

export async function GetRulesByUnitAndService (
  idUnidade: string,
  idServico: string
) {
  // @ts-ignore
  const client = window.apolloClient
  const {
    data: { getRulesByUnitAndService },
    errors
  } = await client.query({
    query: gql`
      query GetRulesByUnitAndService($idUnidade: ID!, $idServico: ID!) {
        getRulesByUnitAndService(idUnidade: $idUnidade, idServico: $idServico) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            serviceCrmId
          }
        }
      }
    `,
    variables: {
      idUnidade,
      idServico
    }
  })

  // @ts-ignore
  dispatchGraphError(errors)

  return getRulesByUnitAndService
}

export async function GetRule (ruleId: String) {
  // @ts-ignore
  const client = window.apolloClient
  const {
    data: { getRule },
    errors
  } = await client.query({
    query: gql`
      query GetRule($ruleId: ID!) {
        getRule(ruleId: $ruleId) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            serviceCrmId
            intervals {
              id
              startTime
              endTime
              isSunday
              isMonday
              isTuesday
              isWednesday
              isThursday
              isFriday
              isSaturday
            }
            ruleControllerGroup {
              id
              ruleId
              controllerGroupId
            }
            ruleVisits {
              id
              ruleId
              visitId
            }
          }
        }
      }
    `,
    variables: {
      ruleId
    }
  })

  // @ts-ignore
  dispatchGraphError(errors)

  return getRule
}

export async function createRule (rule: GuardianRuleInput) {
  // @ts-ignore
  const client = window.apolloClient
  const {
    data: { createRule },
    errors
  } = await client.mutate({
    mutation: gql`
      mutation CreateRule($rule: GuardianRuleInput) {
        createRule(rule: $rule) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            serviceCrmId
            intervals {
              id
              startTime
              endTime
              isSunday
              isMonday
              isTuesday
              isWednesday
              isThursday
              isFriday
              isSaturday
            }
            ruleControllerGroup {
              id
              ruleId
              controllerGroupId
            }
          }
        }
      }
    `,
    variables: {
      rule
    }
  })
  
  // @ts-ignore
  dispatchGraphError(errors)

  return createRule
}

export async function updateRule (request: GuardianRuleUpdateInput) {
  // @ts-ignore
  const client = window.apolloClient
  const {
    data: { updateRule },
    errors
  } = await client.mutate({
    mutation: gql`
      mutation updateRule($rule: GuardianRuleUpdateInput) {
        updateRule(rule: $rule) {
          succeeded
          errors
          data {
            id
            uId
            name
            unitCrmId
            serviceCrmId
            intervals {
              id
              startTime
              endTime
              isSunday
              isMonday
              isTuesday
              isWednesday
              isThursday
              isFriday
              isSaturday
            }
            ruleControllerGroup {
              id
              ruleId
              controllerGroupId
            }
          }
        }
      }
    `,
    variables: {
      rule: {
        uId: request.uId,
        name: request.name,
        unitCrmId: request.unitCrmId,
        serviceCrmId: request.serviceCrmId,
        intervals: request.intervals,
        controllerGroupId: request.controllerGroupId,
        visitId: request.visitId
      }
    }
  })
  
  // @ts-ignore
  dispatchGraphError(errors)

  return updateRule
}