export const tipoMatriculaEnum = {
    Comunidade: 284360003,
    Movimentacao: 936190001,
    Nova: 284360000,
    Rematricula: 284360001,
    ReservaComPagamento: 284360004,
    ReservaSemPagamento: 284360005,
    Upselling: 284360002,
    UpsellingAvulso: 284360007,
    UpsellingExterno: 284360006
}

Object.freeze(tipoMatriculaEnum);